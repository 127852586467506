import { AndroidSetting } from '@root/app/shared/android-settings.types';

export const sortAndroidSettings = (a: AndroidSetting, b: AndroidSetting) => {
	if (a.setting.name < b.setting.name) {
		return -1;
	}

	if (a.setting.name > b.setting.name) {
		return 1;
	}

	return 0;
};
