import { AndroidApp, AndroidAppConfig } from '@root/app/shared/android-apps.types';

export interface AndroidAppServerResponse extends Omit<AndroidAppConfig, 'appResponse'> {
	app: AndroidApp;
}

export const mapPolicyApps = (data: AndroidAppServerResponse[]): AndroidAppConfig[] =>
	data.map((appResp) => ({
		appId: appResp.appId,
		appResponse: appResp.app,
		appVersion: appResp.appVersion,
		differentThanGroup: appResp.differentThanGroup,
		installed: appResp.installed,
		internetConfig: appResp.internetConfig,
		status: appResp.status,
	}));
