import { AndroidAppServerResponse, mapPolicyApps } from '@root/app/utils/map-policy-apps';

import { sortAndroidSettings } from '../../policy-details/utils/sort-android-settings';
import { AndroidDevice } from '../device-details.types';

export const mapDeviceDetails = (device: AndroidDevice) => {
	const policyAndroidSettings = [...device.policy.policyAndroidSettings];

	policyAndroidSettings.sort(sortAndroidSettings);

	const policyAppJoins = mapPolicyApps(
		device.policy.policyAppJoins as unknown as AndroidAppServerResponse[],
	);

	return {
		...device,
		policy: {
			...device.policy,
			policyAndroidSettings,
			policyAppJoins,
		},
	};
};
